<template>
  <div class="full-line flex-col white-bg-color">
    <div class="action_container flex-row align-center">
      <div class="action_button_container full-height flex-row align-center" :class="showAction ? 'show': 'hide'">
        <div class='action_button flex-row align-center justify-center'>
          <div class='text-14 white-font-color' @click="likeClick">{{ liked ? '取消点赞' : '点赞' }}</div>
        </div>
        <div style='width:1px;height:50%;background-color:#333;'></div>
        <div class='action_button flex-row align-center justify-center'>
          <div class='text-14 white-font-color' @click="readClick">已阅</div>
        </div>
        <div style='width:1px;height:50%;background-color:#333;'></div>
        <div class='action_button flex-row align-center justify-center'>
          <div class='text-14 white-font-color' @click="commentClick">评论</div>
        </div>
      </div>
      <div class="dot_container flex-row align-center justify-center" @click="actionClick">
        <div class="dot_circle"></div>
        <div class="dot_circle" style="margin-left: 5px;"></div>
      </div>
    </div>
    <div v-if="likedList.length > 0" class='liked_container flex-row align-center'>
      <img src='@/static/images/icon_like.png' style="width:20px; height: 10px;object-fit: contain;" />
      <div class='text-14 blue' style="text-align: left;margin-left: 5px;line-height: 1.5em;">{{ likedName }}</div>
    </div>
    <div v-if="commentList.length > 0" class='comment_list_container'>
      <div v-for="(comment, index) in commentList" :key='index' class='flex-row full-line' style="margin: 5px 0;" @click='replyClick(comment)'>
        <template v-if="(collapsed && index < 3) || !collapsed">
          <div class='text-14 blue' style='flex: 0 0 auto;'>{{ comment.user_name+'（'+formatDate(comment.time,'MM-dd hh:mm')+'）' }}：</div>
          <div class='text-14 default-font-color fill-row' style="text-align: left; line-height: 1.2em;white-space: pre-wrap;">{{ comment.content }}</div>
          <div class="text-14 red-font-color" v-if="comment.user_id === $store.state.user.userId" style="flex: 0 0 40px;width: 40px;" @click.stop="deleteClick(comment)">删除</div>
        </template>
      </div>
    </div>
    <div v-if="commentList.length > 3" class="collapsed_container flex-row align-center justify-center blue text-14" @click="collapsed = !collapsed">{{collapsed ? '展开' : '折叠'}}</div>
    <!-- <div v-if="showComment" class="full-line full-height flex-col align-center justify-center" style="position: fixed;background-color: rgba(0, 0, 0, 0.5);top: 0;left: 0;">
      <div class="white-bg-color flex-col align-center" style="height: 50%;width: 90%;border-radius: 5px;overflow: hidden;">
        <div class="text-18 default-font-color" style="margin: 20px 0;">{{ title }}</div>
        <div class="fill-col" style="width: 90%;">
          <textarea v-model="content" class="full-height full-line default-font-color" style="border: 0;background-color: #EFEFEF;padding: 10px;resize: none;border-radius:5px;" placeholder="请输入评论..."></textarea>
        </div>
        <div class="flex-row full-line" style="height: 50px; flex: 0 0 50px;margin-top: 20px;">
          <div class="full-height fill-row default-font-color flex-row align-center justify-center" @click="showComment=false">取消
          </div>
          <div style="background-color: #ccc; height: 80%; width: 1px;flex: 0 0 1px;"></div>
          <div class="full-height fill-row base-bg-color white-font-color flex-row align-center justify-center" @click="confirmClick">确定
          </div>
        </div>
      </div>
    </div> -->
    <Modal v-model="showComment" @on-ok='confirmClick'>
      <textarea v-model="content" class="full-line default-font-color" style="height:150px;border: 0;background-color: #EFEFEF;padding: 10px;resize: none;border-radius:5px;" placeholder="请输入评论..."></textarea>
    </Modal>
    <div class="full-line white-bg-color" style="flex: 0 0 10px;height: 10px;" v-if="likedList.length>0||commentList.length>0"></div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/format.js";
import { GetReply, ReplyDaily, DeleteDailyReply } from '@/api';

export default {
  name: "comment",
  props: {
    id: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return [{}];
      },
    },
    order_name: {
      type: String,
      default: '工作日志'
    }
  },
  computed: {
    liked: function () {
      return this.replies.filter(reply => reply.type === 0 && reply.user_id === this.$store.state.user.userId).length === 1;
    },
    likeId: function () {
      if (this.replies.filter(reply => reply.type === 0 && reply.user_id === this.$store.state.user.userId).length === 1) {
        return this.replies.filter(reply => reply.type === 0 && reply.user_id === this.$store.state.user.userId)[0].id;
      }
      return "";
    },
    likedList: function () {
      return this.replies.filter(reply => reply.type === 0);
    },
    likedName: function () {
      return this.likedList.map(item => item.user_name).join(",");
    },
    commentList: function () {
      return this.replies.filter(reply => reply.type === 1);
    }
  },
  data: function () {
    return {
      showAction: false,
      showComment: false,
      content: "",
      replies: this.data,
      title: "",
      pid: "",
      collapsed: true
    }
  },
  methods: {
    formatDate: formatDate,
    actionClick: function () {
      this.showAction = !this.showAction;
    },
    likeClick: function () {
      if (this.liked) {
        this.deleteLikeRequest();
      } else {
        this.likeRequest();
      }
      this.showAction = false;
    },
    readClick: function () {
      ReplyDaily({
        model: {
          pid: "",
          daily_id: this.id,
          type: 1,
          content: "已阅",
          order_name: this.order_name
        },
        userToken: {
          userId: this.$store.state.user.userId,
        }
      }).then(res => {
        if (res.code === 0) {
          this.replyRequest();
        }
      }).catch(err => {
        console.log(err);
      });
      this.showAction = false;
    },
    commentClick: function () {
      this.title = "评论"
      this.showAction = false;
      this.showComment = true;
      this.pid = "";
      this.content = "";
    },
    replyClick: function (item) {
      this.pid = item.id;
      this.title = "回复-" + item.user_name;
      this.showComment = true;
      this.content = "";
    },
    deleteClick: function (item) {
      this.deleteCommentRequest(item.id)
    },
    confirmClick: function () {
      this.commenRequest(this.pid);
    },
    replyRequest: function () {
      GetReply({
        id: this.id,
        userToken: {
          userId: this.$store.state.user.userId,
        },
      }).then(res => {
        this.replies = res.data;
      }).catch(err => {
        console.log(err);
      });
    },
    likeRequest: function () {
      ReplyDaily({
        model: {
          pid: "",
          daily_id: this.id,
          type: 0,
          order_name: this.order_name
        },
        userToken: {
          userId: this.$store.state.user.userId,
        }
      }).then(res => {
        if (res.code === 0) {
          this.replyRequest();
        }
      }).catch(err => {
        console.log(err);
      });
    },
    commenRequest: function (pid = '') {
      ReplyDaily({
        model: {
          pid: pid,
          daily_id: this.id,
          type: 1,
          content: this.content,
          order_name: this.order_name
        },
        userToken: {
          userId: this.$store.state.user.userId,
        }
      }).then(res => {
        if (res.code === 0) {
          this.replyRequest();
          this.showComment = false;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    deleteLikeRequest: function () {
      DeleteDailyReply({
        id: this.likeId,
        userToken: {
          userId: this.$store.state.user.userId,
        }
      }).then(res => {
        if (res.code === 0) {
          this.replyRequest();
        }
      }).catch(err => {
        console.log(err);
      });
    },
    deleteCommentRequest: function (id) {
      DeleteDailyReply({
        id: id,
        userToken: {
          userId: this.$store.state.user.userId,
        }
      }).then(res => {
        if (res.code === 0) {
          this.replyRequest();
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },
  mounted() {
    if (this.data && this.data.length == 1 && !this.data[0].id) {
      this.replyRequest();
    }
  }
}
</script>

<style scoped>
.action_container {
  width: 100%;
  height: 35px;
  flex: 0 0 35px;
  justify-content: flex-end;
}

.dot_container {
  margin-right: 10px;
  height: 24px;
  width: 35px;
  background-color: #efefef;
  border-radius: 3px;
}

.dot_circle {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #4a90e2;
}

.action_button_container {
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #4a5254;
  transition: 0.1s ease-in-out;
}

.show {
  width: 210px;
  flex: 0 0 210px;
}

.hide {
  width: 0;
  flex: 0 0 0;
}

.action_button {
  width: 50%;
  height: 100%;
}

.liked_container {
  margin: 5px 10px 0 10px;
  background-color: #f2f3f5;
  padding: 5px 10px;
  height: auto;
}

.blue {
  color: #4a90e2;
}

.comment_list_container {
  border-top: 0.5px solid #dbdfe0;
  background-color: #f2f3f5;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: column;
  padding: 5px 10px;
}

.collapsed_container {
  background-color: #f2f3f5;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  flex: 0 0 30px;
  border-top: 0.5px solid #dbdfe0;
}

textarea:focus {
  outline: none;
}
</style>
